import { getYear, PAGE_TITLE_VAR } from "./utils";

const labels = {
  // Labels
  appName: PAGE_TITLE_VAR,
  allRightsReserved: (appName) =>
    `© ${getYear()} ${appName} Todos los derechos reservados`,
  home: {
    title: "Vuélvete millonario jugando la lotería en linea!",
    subtitle:
      "Conviértete en el próximo millonario con tan solo un clic jugando a través de",
    startNow: "Empieza ya!",
    playNow: "Juega ahora!",
    redirecting: "Redirigiendo...",
    redirectingMessage:
      "Por favor espera mientras te redirigimos a la página solicitada.",
    redirectingError: "Ocurrio un error al redirigir. redirigiendo a inicio...",
    roulette: {
      claim: "Reclamar recompensa",
      cancel: "Cancelar",
    },
    applyingPromotion: "Aplicando promoción...",
    applyingPromotionMessage:
      "Por favor espera mientras aplicamos el código promocional.",
    promotionApplied: "Promoción aplicada exitosamente.",
    promotionAppliedError: "Ocurrio un error al aplicar la promoción.",
  },
  unknownServerError:
    "Ha ocurrido un error desconocido en el servidor, favor de intentarlo de nuevo.",
  formError: "Por favor corrija los errores en el formulario.",
  tableModal: {
    emptyText: "No se encontraron registros.",
    okButton: "Seleccionar",
    cancelButton: "Cancelar",
    placeholderSearch: "Buscar...",
  },
  breadcrumb: {
    home: "Inicio",
  },
  layout: {
    header: {
      userMenu: {
        verify: "Verificar teléfono",
        referral: "Referidos",
        notifications: "Notificaciones",
        ticketList: "Lista de jugadas",
        transferList: "Transacciones",
        balanceMovements: "Movimientos de saldo",
        changePassword: "Cambiar contraseña",
        cashBalanceReport: "Reporte de cuadre",
        promotion: "Promoción",
        logout: "Salir",
      },
      adminMenu: {
        profile: "Perfil",
        changePassword: "Cambiar contraseña",
        logout: "Salir",
      },
      balance: "Balance:",
      playNow: "Juega ahora!",
      deposit: "Depósito",
      withdraw: "Retiro",
      balanceMovements: "Ver movimientos",
      notifications: {
        title: "Notificaciones",
        all: "Ver todas",
        new: "Nuevo",
        emptyText: "No se encontraron notificaciones.",
      },
    },
  },
  exception: {
    title: "Excepción",
    ACCESS_DENIED: "Acceso denegado",
    ERROR_UNKNOWN:
      "Se produjo un error desconocido, actualice y vuelva a intentarlo.",
    ERROR_NOT_FOUND: "No encontrado",
    UNEXPECTED_ERROR: "Error inesperado",
  },
  login: {
    title: `Bienvenido a`,
    subtitle: "Inicio de sesión al panel administrativo",
    email: {
      label: "Email",
      placeholder: "Introduce tu email.",
    },
    password: {
      label: "Contraseña",
      placeholder: "Introduce tu contraseña.",
    },
    rememberme: "Recordarme",
    forgot: "¿Olvidaste tu contraseña?",
    login: "Iniciar sesión",
    success: "¡Bienvenido!",
    errorMessage: "El email o la contraseña son incorrectos.",
    error: "Error",
  },
  passwordReset: {
    title: "Restablecer contraseña",
    subtitle: "Recuperar contraseña",
    verification: "Verificación de cambio de contraseña",
    email: {
      label: "Email",
      placeholder: "Introduce tu email.",
    },
    sendResetCode: "Enviar código",
    loginHere: "Iniciar sesión aquí",
    success: "¡Enviamos un enlace para restablecer tu contraseña!",
    errorMessage: "El email no existe.",
    error: "Error",
  },
  admins: {
    title: "Usuarios (Admin)",
    pageTitleCreate: "Crear usuario (Admin)",
    pageTitleEditing: "Editando usuario (Admin)",
    subtitle: "Lista de usuarios administradores",
    add: "Añadir",
    edit: "Editar",
    delete: "Eliminar",
    placeholderSearch: "Buscar por nombre o email.",
    deletedSuccess: "Usuario eliminado exitosamente.",
    deleteError: "Ocurrio un error al eliminar el usuario.",
    table: {
      emptyText: "No se encontraron usuarios registrados.",
      columns: {
        name: "Nombre",
        email: "Email",
        createdAt: "Creado en",
        updatedAt: "Actualizado en",
        status: {
          title: "Estado",
          active: "Activo",
          inactive: "Inactivo",
        },
        action: {
          title: "Acciones",
          edit: "Editar",
          delete: "Eliminar",
        },
      },
    },
    confirm: {
      title: "¿Estás seguro de eliminar este usuario?",
      subtitle: "Esta acción no se puede deshacer.",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    },
    form: {
      titleEdit: "Editar usuario",
      titleAdd: "Añadir usuario",
      save: "Guardar",
      saveAndClose: "Guardar & cerrar",
      goBack: "Atrás",
      loading: "Cargando...",
      name: {
        label: "Nombre",
        placeholder: "Introduce tu nombre.",
      },
      email: {
        label: "Email",
        placeholder: "Introduce tu email.",
      },
      password: {
        label: "Contraseña",
        placeholder: "Introduce tu contraseña.",
      },
      password_confirmation: {
        label: "Confirmar contraseña",
        placeholder: "Introduce confirmación de contraseña.",
      },
      status: "Activar/Desactivar",
      roles: "Gestionar roles",
    },
  },
  users: {
    title: "Usuarios",
    pageTitleCreate: "Crear usuario",
    pageTitleEditing: "Editando usuario",
    subtitle: "Lista de usuarios",
    add: "Añadir",
    edit: "Editar",
    delete: "Eliminar",
    placeholderSearch: "Buscar por nombre o email.",
    deletedSuccess: "Usuario eliminado exitosamente.",
    deleteError: "Ocurrio un error al eliminar el usuario.",
    copied: "link copiado al portapapeles",
    copyLinkToClipboard: "Copiar link al portapapeles",
    table: {
      emptyText: "No se encontraron usuarios registrados.",
      columns: {
        users: "Usuarios",
        identification_card: "Cédula",
        user: "Usuario",
        email: "Email",
        createdAt: "Creado en",
        province: "Provincia",
        country: "Código país",
        commission: "Comisión",
        balance: "Balance",
        withdrawBalance: "Balance retiros",
        status: {
          title: "Estado",
          active: "Activo",
          inactive: "Inactivo",
        },
        action: {
          title: "Acciones",
          edit: "Editar",
          delete: "Eliminar",
          paymentLink: "Link de pago",
          notify: "Notificar",
          paymentClient: {
            SKYCHAIN: "Skychain",
            MONEYGRATOR: "Moneygrator",
            PAYWALL: "Paywall",
            CHARGEMONEY: "Charge Money",
          },
        },
      },
    },
    confirm: {
      title: "¿Estás seguro de eliminar este usuario?",
      subtitle: "Esta acción no se puede deshacer.",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    },
    form: {
      title: "Información de",
      titleEdit: "Editar usuario",
      titleAdd: "Añadir usuario",
      save: "Guardar",
      saveAndClose: "Guardar & cerrar",
      goBack: "Atrás",
      loading: "Cargando...",
      joinedAt: "Se unió el",
      referedBy: "Referido por:",
      referralCode: "ID Referido:",
      referralCodeRequired: "ID Referido es requerido.",
      is_seller: {
        label: "¿Es Vendedor?",
        yes_label: "Si",
        no_label: "No",
      },
      commission_profile: {
        label: "Perfil de comisión",
        placeholder: "Selecciona el perfil de comisión.",
      },
      transaction_profile: {
        label: "Perfil de transacción",
        placeholder: "Selecciona el perfil de transacción.",
      },
      commission_percentage: {
        label: "Porcentaje de comisión",
        placeholder: "Selecciona el porcentaje de comisión.",
      },
      identification_card: {
        label: "Cédula",
        placeholder: "Introduce tu cédula.",
      },
      firstname: {
        label: "Nombre",
        placeholder: "Introduce tu nombre.",
      },
      lastname: {
        label: "Apellido",
        placeholder: "Introduce tu apellido.",
      },
      email: {
        label: "Email",
        placeholder: "Introduce tu email.",
      },
      whatsapp: {
        label: "Whatsapp",
        placeholder: "Introduce tu whatsapp.",
      },
      password: {
        label: "Contraseña",
        placeholder: "Introduce tu contraseña.",
      },
      password_confirmation: {
        label: "Confirmar contraseña",
        placeholder: "Introduce confirmación de contraseña.",
      },
      province: {
        label: "Provincia",
        placeholder: "Selecciona tu provincia.",
      },
      country: {
        label: "Código país",
        placeholder: "Seleccione código de país.",
      },
      address: {
        label: "Dirección",
        placeholder: "Introduce tu dirección.",
      },
      city: {
        label: "Ciudad",
        placeholder: "Introduce tu ciudad.",
      },
      paymentClient: {
        label: "Cliente de pago",
        placeholder: "Selecciona el cliente de pago.",
        CHARGEMONEY: "Charge Money",
        MONEYGRATOR: "Moneygrator",
        PAYWALL: "Paywall",
        SKYCHAIN: "Skychain",
      },
      status: "Activar/Desactivar",
      email_verified: "Verificación Email",
      whatsapp_verified: "Verificación SMS",
      tabs: {
        generalInfo: {
          title: "Información general",
        },
        assignedLotteries: {
          title: "Loterias asignadas",
        },
        creditCards: {
          title: "Tarjetas de crédito",
        },
      },
      assignedLotteries: {
        title: "Loterias asignadas",
        subtitle: "Click en el botón añadir para asignar loterias.",
        add: "Añadir",
        table: {
          emptyText: "No se encontraron loterias asignadas.",
          columns: {
            lottery: "Loteria",
            startDate: "Fecha inicio",
            quinielaFirst: "Quiniela 1ra",
            paleFirst: "Pale 1ra",
            tripletaFirst: "Tripleta 1ra",
            superpaleFirst: "Super pale 1ra",
            status: {
              title: "Estado",
              active: "Activo",
              inactive: "Inactivo",
            },
            actions: {
              title: "Acciones",
              edit: "Editar",
              delete: "Eliminar",
              moreInfo: "Más info.",
            },
            assignations: "Asignaciones",
          },
          confirmDelete: {
            title: "¿Estás seguro de eliminar esta asignación?",
            content: "Esta acción no se puede deshacer.",
            okText: "Sí, eliminar",
            cancelText: "Cerrar",
            error: "Ocurrio un error al eliminar la asignación.",
            success: "Asignación eliminada exitosamente.",
          },
        },
        moreInfo: {
          title: "Información de loteria {name}",
          startDate: "Fecha inicio",
          status: "Estado",
          prizes: "Premios",
          quinielaFirst: "Quiniela 1ra",
          quinielaSecond: "Quiniela 2da",
          quinielaThird: "Quiniela 3ra",
          paleFirst: "Pale 1ra",
          paleSecond: "Pale 2da",
          tripletaFirst: "Tripleta 1ra",
          tripletaSecond: "Tripleta 2da",
          superpaleFirst: "Super pale 1ra",
        },
        modal: {
          edit: "Editando asignación de loteria",
          add: "Añadir asignación de loteria",
          okText: "Agregar",
          updateText: "Actualizar",
          cancelText: "Cancelar",
        },
        form: {
          rewards: "Premios",
          status: "Activar/Desactivar",
          first: {
            label: "Primera",
            placeholder: "0.00",
          },
          second: {
            label: "Segunda",
            placeholder: "0.00",
          },
          third: {
            label: "Tercera",
            placeholder: "0.00",
          },
          quiniela: "Quiniela",
          pale: "Pale",
          tripleta: "Tripleta",
          superpale: "Super pale",
          lotteries: {
            label: "Loterias",
            placeholder: "Selecciona la loteria.",
            selectAll: "Seleccionar todas",
          },
          startDate: {
            label: "Fecha inicio",
            placeholder: "DD/MM/YYYY HH:MM:SS",
          },
          quinielaFirst: {
            label: "Quiniela 1ra",
            placeholder: "00-99",
          },
          quinielaSecond: {
            label: "Quiniela 2da",
            placeholder: "00-99",
          },
          quinielaThird: {
            label: "Quiniela 3ra",
            placeholder: "00-99",
          },
          paleFirst: {
            label: "Pale 1ra",
            placeholder: "00-99",
          },
          paleSecond: {
            label: "Pale 2da",
            placeholder: "00-99",
          },
          tripletaFirst: {
            label: "Tripleta 1ra",
            placeholder: "00-99",
          },
          tripletaSecond: {
            label: "Tripleta 2da",
            placeholder: "00-99",
          },
          superpaleFirst: {
            label: "Super pale 1ra",
            placeholder: "00-99",
          },
        },
      },
      creditCards: {
        title: "Tarjetas de crédito",
        subtitle: "Click en el botón añadir para añadir tarjetas de crédito.",
        add: "Añadir",
        table: {
          emptyText: "No se encontraron tarjetas de crédito registradas.",
          columns: {
            cardNumber: "Número",
            cardExpirationDate: "Expiración",
            country: "País",
            isVerified: {
              title: "Verificada",
              yes: "Si",
              no: "No",
              verified: "Verificada",
              unverified: "No verificada",
            },
            verifyBy: "Verificada por",
            verifyAt: "Verificada en",
            actions: {
              title: "Acciones",
              edit: "Editar",
              delete: "Eliminar",
              moreInfo: "Más info.",
            },
            creditCards: "Tarjetas de crédito",
          },
          confirmDelete: {
            title: "¿Estás seguro de eliminar esta tarjeta de crédito?",
            content: "Esta acción no se puede deshacer.",
            okText: "Sí, eliminar",
            cancelText: "Cerrar",
            error: "Ocurrio un error al eliminar la tarjeta de crédito.",
            success: "Tarjeta de crédito eliminada exitosamente.",
          },
        },
        moreInfo: {
          title: "Información de tarjeta {card_number}",
          startDate: "Fecha inicio",
          status: "Estado",
          prizes: "Premios",
          quinielaFirst: "Quiniela 1ra",
          quinielaSecond: "Quiniela 2da",
          quinielaThird: "Quiniela 3ra",
          paleFirst: "Pale 1ra",
          paleSecond: "Pale 2da",
          tripletaFirst: "Tripleta 1ra",
          tripletaSecond: "Tripleta 2da",
          superpaleFirst: "Super pale 1ra",
        },
        modal: {
          edit: "Editando tarjeta de crédito",
          add: "Añadir tarjeta de crédito",
          okText: "Agregar",
          updateText: "Actualizar",
          cancelText: "Cancelar",
        },
        form: {
          isVerified: "Verificada",
          cardNumber: {
            label: "Número",
            placeholder: "Introduce el número de tarjeta.",
          },
          cardExpirationDate: {
            label: "Fecha de expiración",
            placeholder: "MM/YY",
          },
          country: {
            label: "País",
            placeholder: "Selecciona el país.",
          },
          image: {
            label: "Documento de identidad",
            placeholder: "Subir imagen.",
          },
        },
      },
      is_credit_card_enabled: {
        label: "¿Habilitar tarjeta de crédito?",
      },
      disable_withdrawals: "¿Deshabilitar retiros?",
      enable_cash_balance: "¿Habilitar vista cuadre?",
      transfer_fee_disabled: "¿Deshabilitar comisión por transferencia?",
      profit_withdrawal_only: "¿Permitir solo retiros de ganancias?",
      emails_allowed_transfer: {
        label: "Emails permitidos para transferir",
        placeholder:
          "Introduce los emails permitidos para transferir, luego presiona enter.",
      },
      deposit_account_group: {
        label: "Grupo de cuentas de depósito",
        placeholder: "Selecciona el grupo de cuentas de depósito.",
      },
    },
    paymentLink: {
      title: "Link de pago",
      user: {
        label: "Cliente",
        placeholder: "Cliente seleccionado.",
        required: "El cliente es requerido.",
        systemPayment: "Sistema de pago",
        selectAnOption: "Seleccione una opción",
        paymentMethod: "Método de pago",
      },
      amount: {
        label: "Monto",
        placeholder: "Introduce el monto.",
        required: "El monto es requerido.",
        number: "El monto debe ser un número.",
        min: "El monto debe ser mayor a 100.",
      },
      generate: "Generar link",
      error: "Ocurrio un error al generar el link de pago.",
      success: "Link de pago generado exitosamente.",
      okText: "Copiar link & cerrar",
      copied: "Link copiado al portapapeles.",
      successMessage: "Para copiar el link de pago presione el botón.",
    },
    notificationForm: {
      title: "Notificar a {email}",
      form: {
        subject: {
          label: "Asunto: ",
          placeholder: "Asunto del mensaje.",
          required: "El titulo es requerido.",
        },
        message: {
          label: "Mensaje: ",
          placeholder: "Introduce el mensaje.",
          required: "El mensaje es requerido.",
        },
        send: "Enviar",
        cancel: "Cancelar",
        success: "Notificación enviada exitosamente.",
        error: "Ocurrio un error al enviar la notificación.",
      },
    },
  },
  transactions: {
    title: "Transacciones",
    pageTitleCreate: "Crear transacción",
    pageTitleViewing: "Viendo transacción",
    subtitle: "Lista de transacciones",
    add: "Añadir",
    view: "Ver",
    cancel: "Cancelar",
    filters: {
      title: "Aplicar filtros",
      filters: "Filtros",
      form: {
        cancel: "Cancelar",
        save: "Aplicar",
        dateRange: {
          label: "Rango de fechas",
          placeholder: {
            startdate: "Fecha inicial",
            enddate: "Fecha final",
          },
        },
        ticket: {
          label: "Número de ticket",
          placeholder: "Introduce el número de ticket.",
        },
        reference: {
          label: "Referencia",
          placeholder: "Introduce la referencia.",
        },
        status: {
          label: "Estado",
          placeholder: "Selecciona el estado.",
          all: "Todas",
          cancelled: "Cancelado",
          success: "Procesada",
          pending: "Pendiente",
          rejected: "Rechazado",
        },
        type: {
          label: "Tipo",
          placeholder: "Selecciona el tipo.",
        },
        origin: {
          label: "Origen",
          placeholder: "Selecciona el origen.",
        },
        user: {
          label: "Cliente",
          placeholder: "Selecciona el cliente.",
        },
      },
    },
    placeholderSearch: "Buscar por número o usuario.",
    cancelledSuccess: "Transacción cancelada exitosamente.",
    cancelledError: "Ocurrio un error al cancelar la transacción.",
    rejectedSuccess: "Transacción rechazada exitosamente.",
    rejectedError: "Ocurrio un error al rechazar la transacción.",
    approvedSuccess: "Transacción aprobada exitosamente.",
    approvedError: "Ocurrio un error al aprobar la transacción.",
    generateCommissionSuccess: "Comisión generada exitosamente.",
    generateCommissionError: "Ocurrio un error al generar la comisión.",
    paymentClientUnassigned:
      "No posee un cliente de pago asignado, favor contactar a servicio al cliente.",
    requestWithdrawCancellationAlert: {
      title: "Solicitud de cancelación de retiro",
      description:
        "El usuario ha solicitado cancelación del retiro en la fecha {fecha}.",
    },
    table: {
      emptyText: "No se encontraron transacciones.",
      columns: {
        code: "Número",
        date: "Fecha",
        type: "Tipo",
        bank: "Banco",
        ticket: "Ticket",
        reference: "Ticket/Referencia",
        origin: "Origen",
        amount: "Monto",
        user: "Correo/Cliente",
        created_by: "Creada Por",
        updated_by: "Actualizada Por",
        created_at: "Creada En",
        updated_at: "Actualizada En",
        cancelled: {
          title: "Estado",
          active: "Activo",
          inactive: "Cancelada",
        },
        status: {
          title: "Estado",
          pending: "Pendiente",
          cancelled: "Cancelado",
          rejected: "Rechazado",
          success: "Procesada",
        },
        action: {
          title: "Acciones",
          view: "Ver",
          cancel: "Cancelar",
          reject: "Rechazar",
          moreInfo: "Más info.",
        },
        transactions: "Transacciones",
      },
    },
    confirm: {
      title: "¿Estás seguro que desea cancelar la transacción?",
      subtitle: "Esta acción no se puede deshacer.",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
    },
    confirmGenerateCommission: {
      title: "¿Estás seguro que desea generar la comisión?",
      subtitle: "Esta acción no se puede deshacer.",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
    },
    confirmReject: {
      title: "¿Estás seguro que desea rechazar la transacción?",
      subtitle: "Esta acción no se puede deshacer.",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
    },
    confirmApprove: {
      title: "!Confirmar aprobación de transacción¡",
      subtitle: "Se actualizará el balance del usuario.",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
    },
    confirmWarning: {
      title: "¡Atención!",
      subtitle:
        "Actualmente existe un depósito aplicado con el numero de referencia indicado. favor confirmar que la información es correcta.",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
    },
    confirmPreview: {
      title: "Confirmar datos de transacción",
      subtitle: "Por favor confirme los datos de la transacción.",
      customer: "Cliente",
      email: "Correo",
      amount: "Monto",
      bank: "Banco",
      reference: "Referencia",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Atrás",
    },
    form: {
      title: "Información de",
      titleView: "Transacción",
      titleProcess: "Añadir nueva transacción",
      save: "Procesar",
      saveAndClose: "Procesar & cerrar",
      saveAndConfirm: "Procesar & confirmar",
      cancelTransaction: "Cancelar transacción",
      rejectTransaction: "Rechazar transacción",
      approveTransaction: "Aprobar transacción",
      generateCommission: "Generar comisión",
      goBack: "Atrás",
      loading: "Cargando...",
      joinedAt: "Se unió el",
      vouchersTitle: "Comprobantes",
      uploadOriginVoucher: "Subir comprobante",
      formError: "Por favor corrija los errores en el formulario.",
      originVoucher: {
        label: "Comprobantes de retiro",
      },
      originBank: {
        label: "Cuenta bancaria administración",
        placeholder: "",
      },
      voucher: {
        label: "Comprobante de depósito",
        placeholder: "Subir comprobante.",
        required: "El comprobante es requerido.",
      },
      date: {
        label: "Fecha",
        placeholder: "DD/MM/YYYY",
      },
      rejectReason: {
        label: "Motivo de rechazo",
        placeholder: "Introduce el motivo de rechazo.",
      },
      type: {
        label: "Tipo",
        placeholder: "Selecciona el tipo.",
      },
      ticket: {
        label: "Número de ticket",
        placeholder: "Introduce el número de ticket.",
        modal: {
          title: "Tickets ganadores",
        },
      },
      reference: {
        label: "Referencia",
        placeholder: "Introduce la referencia.",
      },
      origin: {
        label: "Origen",
        placeholder: "Selecciona el origen.",
      },
      amount: {
        label: "Monto",
        placeholder: "Introduce el monto.",
      },
      user: {
        label: "Usuario",
        placeholder: "Selecciona el usuario.",
      },
      email: {
        label: "Correo",
        placeholder: "Introduce el correo.",
      },
      comment: {
        label: "Comentario",
        placeholder: "Introduce el comentario.",
      },
      bank: {
        label: "Banco",
        placeholder: "Selecciona el banco.",
        required: "El banco es requerido.",
      },
      account: {
        label: "Cuenta",
        placeholder: "Introduce la cuenta.",
      },
      account_type: {
        label: "Tipo de cuenta",
        placeholder: "Selecciona el tipo de cuenta.",
        SAVINGS: "Ahorros",
        CHECKING: "Corriente",
      },
      identification_card: {
        label: "Cédula",
        placeholder: "Introduce la cédula.",
      },
      origin_bank: {
        label: "Cuenta bancaria administración",
        placeholder: "Selecciona la cuenta bancaria.",
      },
      origin_voucher: {
        label: "Comprobante de retiro",
        placeholder: "Subir comprobante.",
        required: "El comprobante es requerido.",
      },
      updated_by: {
        label: "Actualizado por",
        placeholder: "Selecciona el usuario.",
      },
      updated_at: {
        label: "Actualizado en",
        placeholder: "Selecciona la fecha.",
      },
      destination_user: {
        label: "Usuario destino",
        placeholder: "Selecciona el usuario destino.",
      },
    },
    moreInfo: {
      title: "Información de transacción {code}",
      date: "Fecha",
      code: "Número",
      reference: "Ticket/Referencia",
      user: "Correo/Cliente",
      type: "Tipo",
      bank: "Banco",
      origin: "Origen",
      amount: "Monto",
      status: "Estado",
    },
    statuses: {
      all: "Todas",
      pending: "Pendientes",
      processed: "Procesadas",
      cancelled: "Canceladas",
      rejected: "Rechazadas",
      pendingWithdraw: "Retiros pendientes",
    },
  },
  blocks: {
    title: "Bloqueos & Limites",
    subtitle: "Lista de bloqueos & limites",
    placeholderSearch: "Buscar por tipo bloqueos o numeros de jugada.",
    add: "Añadir",
    deletedSuccess: "Bloqueo eliminado exitosamente.",
    deletedError: "Ocurrio un error al eliminar el bloqueo.",
    limitAmount: "Límite de monto",
    blockPlay: "Bloqueo de jugada",
    confirm: {
      title: "¿Estás seguro que desea eliminar el bloqueo?",
      subtitle: "Esta acción no se puede deshacer.",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
    },
    filters: {
      title: "Aplicar filtros",
      filters: "Filtros",
      form: {
        cancel: "Cancelar",
        save: "Aplicar",
        dateRange: {
          label: "Rango de fechas",
          placeholder: {
            startdate: "Fecha inicial",
            enddate: "Fecha final",
          },
        },
        blockType: {
          label: "Tipo de bloqueo",
          placeholder: "Selecciona el tipo de bloqueo.",
        },
      },
    },
    table: {
      emptyText: "No se encontraron bloqueos & limites.",
      columns: {
        blocks: "Bloqueos & Limites",
        plays: "Jugadas",
        type: "Tipo",
        blockType: "Tipo de bloqueo",
        amount: "Monto",
        startDate: "Fecha inicio",
        endDate: "Fecha fin",
        lotteries: "Loterias",
        status: "Estado",
        action: {
          title: "Acciones",
          edit: "Editar",
          delete: "Eliminar",
        },
      },
    },
    form: {
      title: "Información de",
      titleView: "Bloqueo & Límite",
      titleProcess: "Añadir nuevo bloqueo ó límite",
      save: "Guardar",
      saveAndClose: "Guardar & cerrar",
      cancelTransaction: "Cancelar bloqueo",
      goBack: "Atrás",
      loading: "Cargando...",
      deleteBlock: "Eliminar",
      plays: {
        label: "Jugada",
        placeholder: "Introduce el número de jugada.",
      },
      blockType: {
        label: "Tipo de bloqueo",
        placeholder: "Selecciona el tipo de bloqueo.",
      },
      lotteries: {
        label: "Loterias",
        placeholder: "Selecciona las loterias.",
      },
      amount: {
        label: "Monto",
        placeholder: "Introduce el monto.",
      },
      startDate: {
        label: "Fecha inicio",
        placeholder: "DD/MM/YYYY HH:MM:SS",
      },
      endDate: {
        label: "Fecha fin",
        placeholder: "DD/MM/YYYY HH:MM:SS",
      },
    },
  },
  promotions: {
    title: "Promociones",
    subtitle: "Lista de promociones",
    placeholderSearch: "Buscar por código o descripción.",
    add: "Añadir",
    deletedSuccess: "Promoción eliminada exitosamente.",
    deletedError: "Ocurrio un error al eliminar la promoción.",
    limitAmount: "Límite de monto",
    blockPlay: "Bloqueo de jugada",
    copiedSuccess: "Link copiado al portapapeles.",
    confirm: {
      title: "¿Estás seguro que desea eliminar la promoción?",
      subtitle: "Esta acción no se puede deshacer.",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
    },
    filters: {
      title: "Aplicar filtros",
      filters: "Filtros",
      form: {
        cancel: "Cancelar",
        save: "Aplicar",
        dateRange: {
          label: "Rango de fechas",
          placeholder: {
            startdate: "Fecha inicial",
            enddate: "Fecha final",
          },
        },
        type: {
          label: "Tipo",
          placeholder: "Selecciona el tipo.",
        },
      },
    },
    table: {
      emptyText: "No se encontraron promociones.",
      columns: {
        promotions: "Promociones",
        code: "Código",
        type: {
          title: "Tipo",
          inmeddiate_amount: "Monto inmediato",
          percentage_next_deposit: "Porcentaje/próximo depósito",
          discount_next_purchase: "Descuento/próxima compra",
        },
        useType: {
          title: "Tipo de uso",
          custom_quantity: "Personalizado",
          every_3_months: "Cada 3 meses",
          every_6_months: "Cada 6 meses",
          every_12_months: "Cada 12 meses",
        },
        uses: "Usos",
        startAt: "Inicio Uso",
        expireAt: "Expiración",
        status: "Estado",
        action: {
          title: "Acciones",
          edit: "Editar",
          delete: "Eliminar",
          copyClientLink: "Copiar link",
        },
      },
    },
    form: {
      title: "Información de",
      titleView: "Promoción",
      titleProcess: "Añadir nueva promoción",
      save: "Guardar",
      saveAndClose: "Guardar & cerrar",
      cancelTransaction: "Cancelar promoción",
      goBack: "Atrás",
      loading: "Cargando...",
      deletePromotion: "Eliminar",
      validations: "VALIDACIONES",
      code: {
        label: "Código",
        placeholder: "Introduce el código.",
      },
      type: {
        label: "Tipo",
        placeholder: "Selecciona el tipo.",
        inmeddiate_amount: "Monto inmediato",
        percentage_next_deposit: "Porcentaje sobre el próximo depósito",
        discount_next_purchase: "Descuento sobre la próxima compra",
      },
      quantity: {
        label: "Cantidad/Porcentaje",
        placeholder: "Introduce la cantidad/porcentaje.",
      },
      useType: {
        label: "Tipo de uso",
        placeholder: "Selecciona el tipo de uso.",
        custom_quantity: "Personalizado",
        every_3_months: "Cada 3 meses",
        every_6_months: "Cada 6 meses",
        every_12_months: "Cada 12 meses",
      },
      uses: {
        label: "Usos",
        placeholder: "Introduce el número de usos.",
      },
      startAt: {
        label: "Inicio Uso",
        placeholder: "DD/MM/YYYY",
      },
      expireAt: {
        label: "Expiración",
        placeholder: "DD/MM/YYYY",
      },
      status: {
        label: "Estado",
        active: "Activo",
        inactive: "Inactivo",
      },
      oatur: {
        label: "Solo aplicar a los referidos de usuario(s)",
      },
      oaturValues: {
        label: "Usuario(s)",
        placeholder: "Selecciona los usuarios.",
      },
      oaturwnda: {
        label:
          "Solo aplicar a los usuarios registrados sin depósito después de determinada cantidad de días",
      },
      oaturwndaValue: {
        label: "Días",
        placeholder: "Introduce la cantidad de días.",
      },
      oatuwditlqod: {
        label:
          "Solo aplicar a los usuarios sin depósito después de determinada cantidad de días",
      },
      oatuwditlqodValue: {
        label: "Días",
        placeholder: "Introduce la cantidad de días.",
      },
      description: {
        label: "Descripción",
        placeholder: "Introduce la descripción.",
      },
    },
  },
  depositAccountGroups: {
    title: "Grupos de cuentas de depósito",
    subtitle: "Lista de grupos de cuentas de depósito",
    placeholderSearch: "Buscar por nombre.",
    add: "Añadir",
    deletedSuccess: "Grupo de cuentas de depósito eliminado exitosamente.",
    deletedError:
      "Ocurrio un error al eliminar el grupo de cuentas de depósito.",
    addModalTitle: "Añadir Cuenta",
    card: {
      emptyText: "No hay cuentas de depósito registradas.",
      actions: {
        delete: "Eliminar",
        edit: "Editar",
      },
    },
    confirm: {
      title:
        "¿Estás seguro que desea eliminar el grupo de cuentas de depósito?",
      subtitle: "Esta acción no se puede deshacer.",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
    },
    table: {
      emptyText: "No se encontraron grupos de cuentas de depósito.",
      columns: {
        id: "ID",
        name: "Nombre",
        accounts: "Cuentas",
        status: {
          title: "Estado",
          active: "Activo",
          inactive: "Inactivo",
        },
        createdAt: "Creado en",
        action: {
          title: "Acciones",
          edit: "Editar",
          delete: "Eliminar",
        },
      },
    },
    form: {
      title: "Información de",
      titleView: "Grupo de cuentas de depósito",
      titleProcess: "Añadir nuevo grupo de cuentas de depósito",
      save: "Guardar",
      saveAndClose: "Guardar & cerrar",
      delete: "Eliminar",
      goBack: "Atrás",
      loading: "Cargando...",
      add: "Añadir cuenta",
      addModalTitle: "Añadir Cuenta",
      cancel: "Cancelar",
      name: {
        label: "Nombre",
        placeholder: "Introduce el nombre.",
      },
      accounts: {
        label: "Cuentas",
        placeholder: "Selecciona las cuentas.",
      },
      link: {
        label: "Link",
        placeholder: "Introduce el link. ej: http(s)://****",
      },
      status: {
        label: "Estado",
        active: "Activo",
        inactive: "Inactivo",
      },
      account: {
        label: "Cuenta",
        placeholder: "Escriba datos de la cuenta.",
      },
    },
  },
  lotteries: {
    title: "Loterias",
    pageTitleCreate: "Crear lotería",
    pageTitleEditing: "Editando lotería",
    subtitle: "Lista de loterias",
    add: "Añadir",
    edit: "Editar",
    delete: "Eliminar",
    placeholderSearch: "Buscar por nombre o abreviado.",
    deletedSuccess: "Loteria eliminada exitosamente.",
    deleteError: "Ocurrio un error al eliminar la loteria.",
    table: {
      emptyText: "No se encontraron loterias registradas.",
      columns: {
        lotteries: "Loterias",
        name: "Loteria",
        country: "Pais Sorteo",
        abbreviated: "Abreviado",
        createdAt: "Creado en",
        status: {
          title: "Estado",
          active: "Activo",
          inactive: "Inactivo",
        },
        action: {
          title: "Acciones",
          edit: "Editar",
          delete: "Eliminar",
        },
      },
    },
    confirm: {
      title: "¿Estás seguro de eliminar esta loteria?",
      subtitle: "Esta acción no se puede deshacer.",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    },
    form: {
      titleEdit: "Editar loteria",
      titleAdd: "Añadir loteria",
      save: "Guardar",
      saveAndClose: "Guardar & cerrar",
      goBack: "Atrás",
      loading: "Cargando...",
      baseInfo: "Datos básicos",
      startTime: "Horario de inicio",
      endTime: "Horario de cierre",
      rewards: "Premios",
      copyMondayTimeToOtherDate: "Poner la hora del lunes a las demás fechas",
      logo: "Suba aqui su logo de loteria",
      name: {
        label: "Loteria",
        placeholder: "Introduce tu loteria.",
      },
      abbreviated: {
        label: "Abreviado",
        placeholder: "Introduce tu abreviado.",
      },
      country: {
        label: "Pais Sorteo",
        placeholder: "Seleccione el Pais de sorteo",
      },
      timezone: {
        label: "Zona horaria",
        placeholder: "Seleccione la zona horaria",
      },
      status: "Activar/Desactivar",
      mondayTime: {
        label: "Lunes",
        placeholder: "--:--",
      },
      tuesdayTime: {
        label: "Martes",
        placeholder: "--:--",
      },
      wednesdayTime: {
        label: "Miércoles",
        placeholder: "--:--",
      },
      thursdayTime: {
        label: "Jueves",
        placeholder: "--:--",
      },
      fridayTime: {
        label: "Viernes",
        placeholder: "--:--",
      },
      saturdayTime: {
        label: "Sábado",
        placeholder: "--:--",
      },
      sundayTime: {
        label: "Domingo",
        placeholder: "--:--",
      },
      first: {
        label: "Primera",
        placeholder: "0.00",
      },
      second: {
        label: "Segundo",
        placeholder: "0.00",
      },
      third: {
        label: "Tercera",
        placeholder: "0.00",
      },
      quiniela: "Quiniela",
      pale: "Pale",
      tripleta: "Tripleta",
      superpale: "Super pale",
      description: {
        label: "Información adicional",
        placeholder: "Introduce la descripción.",
        tooltip:
          "La información adicional se mostrará en la página detalles de resultados de la lotería.",
      },
    },
  },
  raffles: {
    title: "Sorteos",
    pageTitleCreate: "Añadir sorteo",
    pageTitleEditing: "Editando sorteo",
    subtitle: "Listado de sorteos",
    add: "Añadir",
    edit: "Editar",
    delete: "Eliminar",
    placeholderSearch: "Buscar por fecha, numero o loteria.",
    deletedSuccess: "Sorteo eliminado exitosamente.",
    deleteError: "Ocurrio un error al eliminar el sorteo.",
    processingError: "Ocurrio un error al procesar el sorteo.",
    processingSuccess: "Sorteo procesado exitosamente.",
    table: {
      emptyText: "No se encontraron sorteos registrados.",
      columns: {
        raffles: "Sorteos",
        date: "Fecha",
        createdAt: "Creado en",
        updatedAt: "Actualizado en",
        createdBy: "Creado por",
        updatedBy: "Actualizado por",
        action: {
          title: "Acciones",
          edit: "Editar",
          delete: "Eliminar",
        },
      },
    },
    confirm: {
      title: "¿Estás seguro de eliminar este sorteo?",
      subtitle: "Esta acción no se puede deshacer.",
      subtitle2:
        "Las transacciones generadas por este sorteo seran canceladas. Esta acción no se puede deshacer.",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    },
    confirmRaffle: {
      title: "Confirmar sorteo!",
      subtitle: "¿Estás seguro de procesar este sorteo?",
      subtitle2:
        "Una vez procesado los sorteos seleccionados se procederá a ejecutar el proceso <b>Jugadas Ganadoras</b>.",
      confirmButtonText: "Si, confirmar",
      cancelButtonText: "Cancelar",
      confirmedSuccess: "Sorteo confirmado exitosamente.",
    },
    form: {
      titleEdit: "Editar sorteo",
      titleAdd: "Añadir sorteo",
      save: "Guardar",
      saveAndClose: "Guardar & cerrar",
      goBack: "Atrás",
      loading: "Cargando...",
      cleanRow: "Limpiar fila.",
      submitDateError: "Debe seleccionar una fecha para ejecutar acción.",
      confirm: "Confirmar",
      createdBy: "Creado por",
      createdAt: "Fecha de creación",
      confirmedBy: "Confirmado por",
      confirmedAt: "Fecha de confirmación",
      date: {
        label: "Fecha",
        placeholder: "--/--/----",
      },
      lotteries: "Loterías",
      firstPrize: {
        label: "Primera",
        placeholder: "00-99",
      },
      secondPrize: {
        label: "Segunda",
        placeholder: "00-99",
      },
      thirdPrize: {
        label: "Tercera",
        placeholder: "00-99",
      },
      delete: "Eliminar",
      update: "Actualizar",
    },
  },
  joinOurMailList: {
    title: "Únase a nuestra lista de correo para recibir las últimas noticias.",
    form: {
      email: {
        label: "Correo",
        placeholder: "Dirección de correo electrónico.",
      },
      submit: "Subscribir",
      success: "Suscrito satisfactoriamente.",
      successMessage:
        "Gracias por suscribirse a nuestra lista de correo, lo mantendremos informado de todas las novedades de nuestro portal.",
      error: "Error",
      errorMessage:
        "Ocurrio un error al suscribirse a nuestra lista de correo.",
    },
  },
  howToPlay: {
    title: "¿Cómo jugar?",
    subtitle: "Registrate y empieza a ganar!",
    subtitle2: "Recarga tu cuenta y empieza a ganar!",
    step: [
      {
        title: "Registrate",
        description: "",
        link: "register",
      },
      {
        title: "Recarga Tu Cuenta",
        description: "",
        link: "transaction-request",
        query: { type: "DEPOSIT" },
      },
      {
        title: "Juega y Gana!",
        description: "",
        link: "buy",
      },
    ],
  },
  faqs: {
    title: "Preguntas frecuentes",
    subtitle: "Preguntas frecuentes",
    subtitle2: "¿No encuentras lo que buscas?. Contáctanos.",
    watchTheVideo: "Para más información mira el siguiente video",
    playVideo: "Ver Video",
  },
  contactUsForm: {
    title: "Contáctenos",
    subtitle: "Contacto",
    form: {
      name: {
        label: "Nombre",
        placeholder: "Su nombre",
      },
      email: {
        label: "Correo electrónico",
        placeholder: "Introduzca la dirección de correo electrónico",
      },
      subject: {
        label: "Asunto",
        placeholder: "Escribe tu asunto",
      },
      message: {
        label: "Mensaje",
        placeholder: "escribe tu mensaje",
      },
      submit: "Enviar",
      success: "Gracias por contactarnos.",
      successEmail: "en breve nos pondremos en contacto contigo.",
      error: "Error",
      errorMessage: "Ocurrio un error al enviar el mensaje.",
    },
  },
  problemReportForm: {
    title: "Reportar un problema",
    subtitle: "Gracias por contactarnos",
    details:
      "Estamos comprometidos con la calidad del servicio al cliente y lamentamos que haya tenido un problema. Analizaremos cuidadosamente su situación y haremos nuestro mejor esfuerzo para resolverla lo mas pronto posible. Tu opinión nos permite para ser mejores.",
    form: {
      name: {
        label: "Nombre",
        placeholder: "Su nombre",
      },
      email: {
        label: "Correo electrónico",
        placeholder: "Introduzca la dirección de correo electrónico",
      },
      phone: {
        label: "Número telefónico",
        placeholder: "Introduzca el número de teléfono",
      },
      message: {
        label: "Problema",
        placeholder: "Descripción del problema",
      },
      attachments: {
        label: "Adjuntar archivos",
        placeholder: "Adjuntar",
        warning: "¡Atención!",
        maxFiles: "Máximo 5 archivos adjuntos",
      },
      submit: "Enviar",
      success: "Gracias por contactarnos.",
      successEmail: "En breve nos pondremos en contacto.",
      error: "Error",
      errorMessage: "Ocurrio un error al enviar el mensaje.",
    },
  },
  referral: {
    title: "Referidos",
    subtitle: "Refiere a tus amigos y gana dinero!",
    subtitle2: "Gana {reward} por cada referido que se registre con tu código.",
    viewReferralRules: "Ver reglas de referidos",
    viewAllMyReferrals: "Ver todos mis referidos",
    youReceive: "Recibes",
    referralCode: "ID Referido",
    referralLink: "Enlace de referido",
    inviteFiends: "Invita a tus amigos",
    copied: "Copiado al portapapeles",
    loadingRules: "Cargando reglas...",
    qrModal: {
      subtitle: "Refiere a tus amigos y gana dinero!",
    },
    allReferralModal: {
      title: "Todos mis referidos",
      subtitle:
        "Todos los usuarios que se han registrado con tu código ó link de referido.",
      table: {
        emptyText: "No se encontraron referidos.",
        columns: {
          ID: "ID de usuario",
          commission: "Comisión obtenida (RD$)",
          date: "Fecha de registro",
          paid: "Pagado",
        },
      },
    },
    tieredReferralSystem: {
      title: "Sistema Referidos Por Niveles",
      description: `<p>Refiere y gana, recibe un % de comisión por las recargas realizadas por tus referidos.</p><p>Envía el link de referidos directo a los usuarios interesados y usted estará recibiendo el {percentage1}% de comisión (nivel 1) de todos los depósitos realizados. A continuación si el usuario registrado bajo su link de afiliado sigue recomendando y registra más usuario usted estará recibiendo el {percentage2}% (nivel 2) de comisión del monto depósitado.</p>`,
      level1: {
        label: "Nivel 1",
      },
      level2: {
        label: "Nivel 2",
      },
    },
  },
  ticketList: {
    title: "Lista de jugadas",
    subtitle: "Listado de jugadas realizadas",
    placeholderSearch: "Buscar por fecha ó número.",
    table: {
      emptyText: "No se encontraron jugadas registradas.",
      columns: {
        date: "Fecha",
        code: "Número",
        totalAmount: "Monto total",
        status: {
          title: "Estado",
          processed: "Procesado",
          cancelled: "Cancelado",
          paid: "Pagado",
        },
        wonTotalAmount: "Monto ganado",
        action: {
          title: "Acciones",
          view: "Ver",
          cancel: "Cancelar",
        },
        tickets: "Jugadas",
      },
    },
    confirmCancellation: {
      title: "Cancelar ticket",
      content: "Favor de confirmar que desea cancelar el ticket #{code}.",
      okText: "Sí, cancelar",
      cancelText: "Cerrar",
      errorCancellation: "Ocurrio un error al cancelar el ticket.",
      successCancellation: "Ticket cancelado exitosamente.",
    },
  },
  ticketView: {
    title: "Jugada",
    subtitle: "Detalle de jugada",
    loadingText: "Cargando...",
    playDetails: "DETALLES DE JUGADA #",
    guaranteePayment: "Garantia de pago",
    dateLabel: "Fecha: ",
    phoneLabel: "Tel: ",
    ticketNoLabel: "Ticket #: ",
    totalLabel: "Total",
    subtotalLabel: "Subtotal",
    discountAmountLabel: "Descuento",
    promotionLabel: "Promoción: ",
    wonTotalLabel: "Ganado",
    checkTicketLabel: "Revise su ticket",
    noPaymentLabel: "No pagamos sin ticket",
    sellerLabel: "Vendedor",
    clientLabel: "Cliente",
    statusLabel: "Estado: ",
    print: "Imprimir",
    shareTitle: "Compartir ticket",
    copiedToClipboard: "Copiado al portapapeles",
    shareError: "Ocurrio un error al compartir el ticket.",
    rebuyError: "Ocurrio un error al repetir la jugada.",
    shareBtn: "Compartir",
    reBuyBtn: "Repetir jugada",
    errorAreNotPaidLabel: "No se pagan errores",
    table: {
      type: "Tipo Jugada/Loterías",
      typeShort: "PR",
      numbers: "Números",
      lottery: "Loterias",
      amount: "Valor",
      wonAmount: "Ganado",
    },
    statuses: {
      cancelled: "Cancelado",
      processed: "Procesado",
    },
    confirmCancellation: {
      title: "Cancelar ticket",
      content: "Favor de confirmar que desea cancelar el ticket #{code}.",
      okText: "Sí, cancelar",
      cancelText: "Cerrar",
      errorCancellation: "Ocurrio un error al cancelar el ticket.",
      successCancellation: "Ticket cancelado exitosamente.",
      cancelLabel: "Cancelar",
    },
  },
  loginForm: {
    title: "Acceso",
    subtitle: "Bienvenido de nuevo a",
    text: "Iniciar sesión",
    form: {
      email: {
        label: "Cédula ó correo electrónico",
        placeholder: "Cédula ó correo electrónico",
      },
      password: {
        label: "Contraseña",
        placeholder: "Ingresa tu contraseña",
      },
      submit: "Iniciar sesión ahora",
      forgotPassword: "¿Contraseña olvidada?",
      newInApp: (appName) => `¿Nuevo en ${appName}?`,
      register: "Registrarse aquí",
      error: "Error",
      errorMessage: "Ocurrio un error al iniciar sesión.",
      notRobot: "Por favor asegúrate de que eres humano.",
      success: "¡Bienvenido!",
    },
  },
  changePasswordForm: {
    title: "Cambiar contraseña",
    form: {
      current_password: {
        label: "Contraseña actual",
        placeholder: "Contraseña actual",
      },
      password: {
        label: "Nueva contraseña",
        placeholder: "Nueva contraseña",
      },
      password_confirmation: {
        label: "Confirmar contraseña",
        placeholder: "Confirmar contraseña",
      },
      success: "Contraseña cambiada exitosamente.",
      error: "Ocurrio un error al cambiar la contraseña.",
      cancel: "Cancelar",
      submit: "Cambiar Contraseña",
    },
  },
  passwordResetForm: {
    title: "Restablecer la contraseña",
    form: {
      email: {
        label: "Correo electrónico",
        placeholder: "Dirección de correo electrónico",
      },
      submit: "Enviar",
      success: "¡Correo Enviado!",
      successMessage:
        "Se ha enviado un correo electrónico con instrucciones para restablecer la contraseña.",
      error: "Ocurrio un error al enviar el correo electrónico.",
      goToLogin: "Volver a pantalla de acceso",
      okText: "Aceptar",
    },
    form1: {
      password: {
        label: "Nueva contraseña",
        placeholder: "Nueva contraseña",
      },
      password_confirmation: {
        label: "Confirmar contraseña",
        placeholder: "Confirmar contraseña",
      },
      success: "Contraseña cambiada exitosamente.",
      error: "Ocurrio un error al cambiar la contraseña.",
      cancel: "Cancelar",
      submit: "Cambiar Contraseña",
      okText: "Aceptar",
    },
    goToHome: "ir a inicio",
    goToLogin: "Acceder",
  },
  registerForm: {
    title: "Registrarse",
    subtitle: "Registro",
    accept: "Aceptar",
    form: {
      firstname: {
        label: "Nombre",
        placeholder: "Primer nombre",
      },
      lastname: {
        label: "Apellido",
        placeholder: "Apellido",
      },
      identificationCard: {
        label: "Cédula",
        placeholder: "Cédula",
      },
      email: {
        label: "Dirección de correo electrónico",
        placeholder: "Dirección de correo electrónico",
      },
      referralCode: {
        label: "ID de Referencia (Opcional)",
        placeholder: "Ex: XXXXXX",
      },
      password: {
        label: "Contraseña",
        placeholder: "Ingresa tu contraseña",
      },
      passwordConfirm: {
        label: "Confirmar contraseña",
        placeholder: "Confirma tu contraseña",
      },
      whatsapp: {
        label: "Whatsapp",
        placeholder: "Número de Whatsapp",
      },
      city: {
        label: "Ciudad",
        placeholder: "Ciudad",
      },
      province: {
        label: "Provincia",
        placeholder: "Provincia",
      },
      country: {
        label: "Código",
        placeholder: "Código",
      },
      address: {
        label: "Dirección",
        placeholder: "Dirección",
      },
      agreeWidth: "Estoy de acuerdo con las",
      privacityPolicy: "política de privacidad",
      terms: "términos y condiciones",
      notRobot: "Por favor asegúrate de que eres humano.",
      aceptTerms: "Debes aceptar los términos y condiciones.",
      submit: "Regístrate ahora",
      haveAccount: "¿Ya tienes una cuenta?",
      inHere: "Entra aquí",
      success: "Gracias por registrarte.",
      error: "Error",
      errorMessage: "Ocurrio un error al registrarte.",
      verifiedEmailSend: "Se ha enviado un correo electrónico de verificación.",
    },
    confirm: {
      title: "Política de privacidad, términos y condiciones",
      content:
        "Debes aceptar los términos y condiciones y política de privacidad.",
      okText: "Aceptar",
      cancelText: "Cancelar",
    },
  },
  results: {
    title: "Resultados",
    resultsOf: "Resultados del",
    loadingText: "Cargando resultados...",
    notResults: "No se encontraron resultados para esta fecha.",
    raffle: "Sorteo",
    raffleDateLabel: "Fecha del sorteo:",
    lotteryCloseTimeLabel: "Hora Cierre",
  },
  privacyPolicies: {
    title: "Políticas de privacidad",
    subtitle: "Políticas de privacidad",
    loadingText: "Cargando políticas de privacidad...",
    notResults: "No se encontraron políticas de privacidad.",
  },
  termsAndConditions: {
    title: "Términos y condiciones",
    subtitle: "Términos y condiciones",
    loadingText: "Cargando términos y condiciones...",
    notResults: "No se encontraron términos y condiciones.",
  },
  playNow: {
    title: "!Juega Ahora!",
  },
  cart: {
    title: "Listado de jugadas",
    add: "Agregar jugada",
    ticketList: "Lista de jugadas",
    selectLotteries: "Selecciona las loterías",
    empty: "No ha agregado jugada al carrito.",
    emptyText:
      "Puedes agregar productos a tu carrito haciendo click en el botón de agregar jugada.",
    notAvailableLottery:
      "Esta lotería no está disponible por el momento, esta jugada no podrá ser procesada.",
    date: "Fecha:",
    total: "TOTAL:",
    goToCheckout: "Pagar",
    deleteError: "Ocurrio un error al eliminar la jugada.",
    deleteSuccess: "Jugada eliminada exitosamente.",
    table: {
      play: "Jugada",
      type: "Tipo de jugada",
      lottery: "Loteria",
      numbers: "Números",
      amount: "Monto",
      action: {
        title: "Acción",
        remove: "Eliminar Jugada",
      },
    },
    popout: {
      lotteriesTitle: "Loteria(s)",
      playListTitle: "Jugada(s)",
      validatePlays: "Validación",
      next: "Siguiente",
      prev: "Anterior",
      superpale: "Super pale",
      cancel: "Cancelar",
      addPlays: "Agregar",
      addPlay: "Agregar jugada",
      loadingMessage: "Cargando loterias...",
      lotteriesError: "Debe seleccionar al menos una loteria.",
      lotteriesSuperpaleError:
        "Debe seleccionar dos loterias o más para el super pale.",
      mixPlaysError:
        "Al menos dos números diferentes son requeridos, para procesar la mezcla de números.",
      mixError:
        "La(s) siguiente(s) jugada(s) no puede(n) ser mezclada(s), debe ser números entre 00-99:",
      playsError: "Debe completar al menos una jugada.",
      addNewEntry: "Agregar nueva jugada",
      required: "Requerido",
      amount: "Monto",
      play: "Jugada",
      playType: "Tipo de jugada",
      numbers: "Números",
      numbersPlaceholder: "00-00-00",
      superPalePlaceholder: "00-00",
      amountPlaceholder: "0.00",
      quiniela: "Quiniela",
      pale: "Pale",
      tripleta: "Tripleta",
      addPlaysSuccess: "Jugada agregada exitosamente.",
      totalLabel: "Total",
      mix: "Mezclar",
    },
    payment: {
      title: "Revisa tu ticket",
      payTicket: "Pagar Ticket",
      totalTicket: "Total:",
      success: "Ticket pagado & generado exitosamente.",
      ticketLabel: "Ticket #:",
      pay: "Pagar",
      noAvailablePlays: "No hay jugadas disponibles.",
    },
    success: {
      cancelText: "Aceptar",
      okText: "Ver/Imprimir Ticket",
    },
  },
  404: {
    code: "404",
    title: "Página no encontrada",
    goToHome: "ir a inicio",
  },
  accountVerification: {
    title: "Verificación de cuenta",
    success: "verificación de correo electrónico exitosa",
    error: "Ocurrio un error al verificar la cuenta.",
    goToHome: "ir a inicio",
    goToLogin: "Acceder a mi cuenta",
    invalidToken: "Token de verificación inválido.",
    verifying: "Verificando...",
  },
  orders: {
    title: "Tickets de jugadas",
    singular: "Ticket de jugada",
    placeholderSearch: "Buscar",
    filters: {
      title: "Aplicar filtros",
      filters: "Filtros",
      form: {
        cancel: "Cancelar",
        save: "Aplicar",
        dateRange: {
          label: "Rango de fechas",
          placeholder: {
            startdate: "Fecha inicial",
            enddate: "Fecha final",
          },
        },
        code: {
          label: "Número de ticket",
          placeholder: "Introduce el número de ticket.",
        },
        status: {
          label: "Estado",
          placeholder: "Selecciona el estado.",
          cancelled: "Cancelado",
          processed: "Procesado",
        },
        user: {
          label: "Cliente",
          placeholder: "Selecciona el cliente.",
        },
      },
    },
    table: {
      emptyText: "No hay tickets de jugadas.",
      columns: {
        codeNumber: "Número de ticket",
        code: "Número",
        date: "Fecha",
        user: "Cliente",
        totalAmount: "Monto total",
        wonTotalAmount: "Monto ganado",
        paid: "Pagado",
        status: {
          title: "Estado",
          processed: "Procesado",
          cancelled: "Cancelado",
        },
        action: {
          title: "Acción",
          view: "Ver",
          cancel: "Cancelar",
        },
      },
    },
    view: {
      loading: "Cargando...",
      goBack: "Volver",
      title: "Ticket",
      subtitle: "Detalle de ticket",
      loadingText: "Cargando...",
      playDetails: "DETALLES DE TICKET #",
      guaranteePayment: "Garantia de pago",
      dateLabel: "Fecha: ",
      phoneLabel: "Tel: ",
      ticketNoLabel: "Ticket #: ",
      totalLabel: "Total",
      discountAmountLabel: "Descuento",
      subtotalLabel: "Subtotal",
      wonTotalLabel: "Ganado",
      checkTicketLabel: "Revise su ticket",
      noPaymentLabel: "No pagamos sin ticket",
      promotionLabel: "Promoción: ",
      sellerLabel: "Vendedor",
      clientLabel: "Cliente",
      print: "Imprimir",
      cancel: "Cancelar",
      cancelConfirmation: {
        title: "Cancelar ticket",
        content: "Favor de confirmar que desea cancelar el ticket #{code}.",
        okText: "Sí, cancelar",
        cancelText: "Cerrar",
        errorCancellation: "Ocurrio un error al cancelar el ticket.",
        successCancellation: "Ticket cancelado exitosamente.",
        cancelLabel: "Cancelar",
      },
      cancelledBy: {
        admin: "Admin: ",
        user: "Usuario: ",
        date: "Cancelado el: ",
      },
      statuses: {
        label: "Estado: ",
        cancelled: "Cancelado",
        processed: "Procesado",
      },
      table: {
        type: "Tipo Jugada/Loterías",
        typeShort: "PR",
        numbers: "Números",
        lottery: "Loterias",
        amount: "Valor",
        wonAmount: "Ganado",
      },
      rafflesTable: {
        lottery: "Lotería",
        firstPrize: "Primera",
        secondPrize: "Segunda",
        thirdPrize: "Tercera",
      },
    },
    userCashBalanceReport: {
      title: "Reporte de cuadre",
      subtitle: "Reporte de cuadre de balance",
      error: "Error al generar el reporte de cuadre.",
      table: {
        emptyTextNoData: "No se encontraron datos para la fecha seleccionada.",
        emptyText: "Generar reporte para ver los datos.",
        generate: "Generar reporte",
        total: "Total",
        totalWon: "Total Premios",
        info: "Información",
        dateRange: {
          label: "Rango de fechas",
          placeholder: {
            startdate: "Fecha inicial",
            enddate: "Fecha final",
          },
          required: "Rango de fechas requerido.",
        },
        columns: {
          date: "Fecha",
          totalAmount: "Subtotal Venta",
          wonTotalAmount: "Total Premios",
        },
      },
    },
  },
  systemConfig: {
    title: "Configuración del sistema",
    tabs: {
      smtpConfig: {
        title: "Configuración de SMTP",
        form: {
          loading: "Cargando configuración...",
          save: "Guardar",
          host: {
            label: "Host",
            placeholder: "Ex.: smtp.gmail.com",
          },
          port: {
            label: "Puerto",
            placeholder: "Ex.: 587",
          },
          encryption: {
            label: "Tipo de encriptación",
            placeholder: "Ex.: tls",
          },
          user: {
            label: "Usuario",
            placeholder: "Ex.: someone@domain.ext",
          },
          password: {
            label: "Contraseña",
            placeholder: "Ex.: ********",
          },
        },
      },
      craueConfig: {
        title: "Variables globales",
        form: {
          loading: "Cargando configuración...",
          save: "Guardar",
          name: {
            label: "Nombre",
          },
          value: {
            label: "Valor",
            placeholder: "Ex.: numero|texto|json",
          },
          description: {
            label: "Descripción",
            placeholder: "Escribe una descripción",
            edit: "Editar",
            save: "Guardar",
          },
        },
      },
      termAndPolicy: {
        title: "Políticas, términos y condiciones",
        form: {
          loading: "Cargando configuración...",
          save: "Guardar",
        },
      },
      frequentQuestionsPageClient: {
        title: "Preguntas frecuentes",
        form: {
          loading: "Cargando configuración...",
          save: "Guardar",
          add: "Agregar",
          delete: "Eliminar",
          question: "Pregunta",
          answer: "Respuesta",
          link: "Enlace",
        },
      },
      carouselHomePageClient: {
        title: "Carrusel página inicio",
        form: {
          loading: "Cargando configuración...",
          save: "Guardar",
          add: "Agregar",
          cancel: "Cancelar",
          addModalTitle: "Agregar diapositiva",
          title: {
            label: "Título",
            placeholder: "Título",
          },
          link: {
            label: "Enlace",
            placeholder: "Introduce un enlace",
          },
          imageURL: {
            label: "URL de la imagen",
            placeholder: "Introduce una URL",
          },
          status: {
            label: "Estado",
            activeLabel: "Activo",
            inactiveLabel: "Inactivo",
          },
        },
        table: {
          emptyText: "El carrusel no contiene diapositivas.",
          columns: {
            title: "Título",
            link: "Enlace",
            imageURL: "Imagen URL",
            status: "Estado",
            actions: {
              title: "Acciones",
              edit: "Editar",
              delete: "Eliminar",
            },
          },
        },
      },
      creditCardPaymentClients: {
        title: "Clientes tarjeta de crédito",
        form: {
          loading: "Cargando configuración...",
          save: "Guardar",
          add: "Agregar",
          cancel: "Cancelar",
          addModalTitle: "Agregar cliente",
          url: {
            label: "URL",
            placeholder: "Dirección URL del cliente.",
          },
          percentage: {
            label: "Percentage",
            placeholder: "Introduce el porcentaje.",
          },
        },
        table: {
          emptyText: "No hay clientes de tarjeta de crédito.",
          columns: {
            url: "URL",
            percentage: "Porcentaje",
            actions: {
              title: "Acciones",
              edit: "Editar",
              delete: "Eliminar",
            },
          },
        },
      },
      depositAccounts: {
        title: "Cuentas de depósito",
        form: {
          loading: "Cargando configuración...",
          save: "Guardar",
          add: "Agregar",
          cancel: "Cancelar",
          addModalTitle: "Agregar cuenta",
          account: {
            label: "Cuenta",
            placeholder: "Formatear cuenta aqui...",
          },
        },
        card: {
          emptyText: "No hay cuentas de depósito registradas.",
          actions: {
            delete: "Eliminar",
            edit: "Editar",
          },
        },
      },
      welcomePage: {
        title: "Página de bienvenida",
        form: {
          loading: "Cargando configuración...",
          save: "Guardar",
        },
      },
    },
  },
  commissions: {
    title: "Reporte de comisiones",
    subtitle: "Generar y pagar reporte de comisiones",
    payGeneratedSucessfully: "Pagos de comisiones efectuados exitosamente.",
    errorGeneratingPay: "Ocurrio un error al generar los pagos de comisiones.",
    noSelectionError: "Debe seleccionar al menos una comisión.",
    confirm: {
      title: "Confirmar",
      subtitle:
        "Favor de confirmar que desea generar el pago del reporte de comisiones.",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    },
    form: {
      date: {
        label: "Mes/Año",
        placeholder: "Selecciona un mes/año",
      },
      user: {
        label: "Vendedor",
        placeholder: "Selecciona un vendedor",
      },
      action: {
        generate: "Generar",
        pay: "Pagar",
        goBack: "Volver",
      },
    },
    table: {
      columns: {
        date: "Fecha",
        user: "Vendedor",
        month: "Mes",
        year: "Año",
        total: "Total",
        percentage: "Porcentaje",
        amount: "Monto",
        paid: "Pagado",
        paidAt: "Fecha de pago",
        createdBy: "Pagado por",
        type: {
          title: "Tipo",
          AFFILIATE_NETWORK_COMMISSION: "Red afiliados",
          REFERRAL_COMMISSION: "Referidos",
          SELLER_COMMISSION: "Ventas",
        },
      },
      summary: {
        total: "Total",
        totalPaid: "Total Pagado",
        totalPending: "Total Pendiente",
        averageCommissionPercentage: "Promedio % de comisiónes",
        totalCommissions: "Total comisiónes",
        totals: "TOTALES",
      },
      emptyText: "No hay comisiones para mes {date}.",
      loading: "Cargando report comisiones...",
    },
  },
  transactionList: {
    title: "Lista de transacciones",
    subtitle: "Listado de transacciones realizadas",
    placeholderSearch: "Buscar por número.",
    newTransaction: "Nueva transacción",
    table: {
      emptyText: "No se encontraron transacciones.",
      columns: {
        date: "Fecha",
        code: "Número",
        type: "Tipo",
        origin: "Origen",
        amount: "Monto",
        status: {
          title: "Estado",
          pending: "Pendiente",
          cancelled: "Cancelado",
          rejected: "Rechazado",
          success: "Procesada",
        },
        action: {
          title: "Acciones",
          view: "Ver",
          reject: "Rechazar",
          delete: "Eliminar",
          cancel: "Cancelar",
          moreInfo: "Más info.",
        },
        transactions: "Transacciones",
      },
    },
    confirmCancellation: {
      title: "Cancelar transacción",
      content: "Favor de confirmar que desea cancelar la transacción #{code}.",
      okText: "Sí, cancelar",
      cancelText: "Cerrar",
    },
    cancelTransferSuccess: "Transacción cancelada exitosamente.",
    cancelTransferError: "Ocurrio un error al cancelar la transacción.",
    withdrawCancellationInProgress: "En proceso de cancelación.",
    confirmRequestWithdrawCancellation: {
      title: "Solicitar cancelar retiro",
      content:
        "Favor de confirmar que desea solicitar la cancelación del retiro #{code}.",
      okText: "Sí, cancelar",
      cancelText: "Cerrar",
    },
    requestCancelWithdrawSuccess:
      "Solicitud de cancelación registrada exitosamente.",
    requestCancelWithdrawError:
      "Ocurrio un error al solicitar la cancelación del retiro.",
    requestWithdrawCancellationAlert: {
      title: "Solicitud de cancelación de retiro",
      description:
        "Su solicitud de retiro esta en proceso de cancelación, este proceso se toma unos minutos ya que requiere de la intervención de un supervisor.",
    },
    moreInfo: {
      title: "Información de transacción {code}",
      date: "Fecha",
      code: "Número",
      reference: "Ticket/Referencia",
      type: "Tipo",
      origin: "Origen",
      amount: "Monto",
      status: "Estado",
    },
  },
  transactionView: {
    title: "Transacción",
    subtitle: "Detalle de transacción",
    loadingText: "Cargando...",
    transactionDetails: "DETALLES DE TRANSACCIÓN #",
    dateLabel: "Fecha: ",
    phoneHolderLabel: "Tel: ",
    transactionNoLabel: "Transacción #: ",
    amountLabel: "Monto: ",
    sellerLabel: "Vendedor: ",
    clientLabel: "Cliente: ",
    print: "Imprimir: ",
    phoneLabel: "Teléfono: ",
    typeLabel: "Tipo: ",
    originLabel: "Origen: ",
    currencyLabel: "Moneda: ",
    bankLabel: "Banco: ",
    accountLabel: "Cuenta: ",
    accountTypeLabel: "Tipo de cuenta: ",
    accountHolderLabel: "Titular de la cuenta: ",
    identificationCardLabel: "Cédula: ",
    emailHolderLabel: "Correo electrónico: ",
    commentLabel: "Comentario: ",
    referenceLabel: "Referencia: ",
    vouchersLabel: "Comprobantes",
    statusLabel: "Estado: ",
    phoneHolder: "Teléfono: ",
    destinationPhoneLabel: "Teléfono/Celular Dest.: ",
    destinationUserLabel: "Nombre destinatario: ",
    voucherLoadError: "Ocurrio un error al cargar el comprobante.",
    confirmCancellation: {
      title: "Cancelar transacción",
      content: "Favor de confirmar que desea cancelar la transacción #{code}.",
      okText: "Sí, cancelar",
      cancelText: "Cerrar",
    },
    cancelTransferSuccess: "Transacción cancelada exitosamente.",
    cancelTransferError: "Ocurrio un error al cancelar la transacción.",
    withdrawCancellationInProgress: "En proceso de cancelación.",
    confirmRequestWithdrawCancellation: {
      title: "Solicitar cancelar retiro",
      content:
        "Favor de confirmar que desea solicitar la cancelación del retiro #{code}.",
      okText: "Sí, cancelar",
      cancelText: "Cerrar",
    },
    requestCancelWithdrawSuccess:
      "Solicitud de cancelación registrada exitosamente.",
    requestCancelWithdrawError:
      "Ocurrio un error al solicitar la cancelación del retiro.",
    requestWithdrawCancellationAlert: {
      title: "Solicitud de cancelación de retiro",
      description:
        "Su solicitud de retiro esta en proceso de cancelación, este proceso se toma unos minutos ya que requiere de la intervención de un supervisor.",
    },
  },
  transactionForm: {
    title: "Solicitud de",
    subtitle: "Solicitud de transacción",
    submittingText: "Enviando solicitud...",
    transactionInfoLabel: "Información de transacción",
    paymentMethodInfoLabel: "Método de pago",
    bankInfoLabel: "Información Bancaria",
    vouchersLabel: "Comprobantes",
    holderInfoLabel: "Información del titular",
    accountNotFound: "Cuenta no encontrada.",
    pay: "Procesar Pago",
    formError: "Error en el formulario.",
    accountNotFoundDescription:
      "No existe una cuenta con el número especificado.",
    infoLabel:
      '<ul class="work-day"><li>Lunes a Sábados: 9:00am a 10:00pm.</li><li>Domingo: 9:00am a 7:00pm.</li></ul><p class="note"><strong>Nota:</strong><br /><span>- Solo se permiten dos retiros por dia.</span>{{more}}</p>',
    depositCommissionInfoLabel:
      "<span>- Recarga y aplicaremos a tu balance un {{variable}}% del monto del recargado.</span><br />",
    depositInfoLabel:
      '<ul class="work-day"><li>Lunes a Domingos: 9:00am a 10:00pm.</li></ul><p class="note"><strong>Nota:</strong><span>{{deposit}}<span>- Las recargas aplicadas, deben ser consumidas dentro de la plataforma, no procesamos devoluciones.</span></span></p>',
    isCreditCardEnabledMessage: `Para habilitar los pagos con tarjeta de credito debe hacer nuestro proceso de verificacion, por favor escribir a nuestro whatsapp: <b>{{numero}}</b> para continuar con el proceso. {{clickhere}}`,
    whatsappMessage2: "Quiero habilitar los pagos con tarjeta de credito.",
    clickhere:
      "<a href='https://wa.link/9ukmq9' target='_blank'>Haz clic aquí.</a>",
    withdrawInfoLabel: "Balance disponible para retiro",
    currency: {
      label: "Moneda",
      placeholder: "Selecciona la moneda.",
      DOP: "Pesos Dominicanos (DOP)",
      USD: "Dólares Americanos (USD)",
      BTC: "Bitcoin (BTC)",
    },
    origin: {
      label: "Tipo",
      placeholder: "Selecciona el tipo.",
      TRANSFER: "Transferencia",
      PLISIO: "Plisio",
    },
    type: {
      label: "Tipo",
      placeholder: "Método de pago.",
      DEPOSIT: "Depósito",
      WITHDRAW: "Retiro",
      TRANSFER: "Transferencia",
      BANK_TRANSFER: "Transferencia bancaria",
      TETHER: "Tether (USDT)",
      CREDIT_CARD: "Tarjeta de crédito",
    },
    amount: {
      label: "Monto",
      placeholder: "0.00",
    },
    accountType: {
      label: "Tipo de cuenta",
      placeholder: "Selecciona el tipo de cuenta.",
      SAVINGS: "Ahorros",
      CHECKING: "Corriente",
    },
    bank: {
      label: "Banco",
      placeholder: "Banco",
    },
    account: {
      label: "Cuenta",
      placeholder: "Cuenta",
    },
    accountHolder: {
      label: "Titular de la cuenta",
      placeholder: "Titular de la cuenta",
    },
    identificationCard: {
      label: "Cédula",
      placeholder: "Cédula",
    },
    phoneHolder: {
      label: "Teléfono",
      placeholder: "Teléfono",
    },
    emailHolder: {
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
    },
    comment: {
      label: "Comentario",
      placeholder: "Comentario",
    },
    voucher: {
      label: "Comprobante",
      placeholder: "Comprobante",
      required: "Debe subir un comprobante.",
    },
    password: {
      label: "Contraseña",
      placeholder: "Escriba su contraseña",
      required: "La contraseña es requerida.",
    },
    reference: {
      label: "Referencia",
      placeholder: "Referencia",
    },
    destinationAccountPhone: {
      label: "Teléfono/Celular",
      placeholder: "(000) 000-0000",
    },
    destinationUser: {
      label: "Nombre destinatario",
      placeholder: "Selecciona el usuario destino.",
    },
    depositTips: [
      "Solicita los datos bancarios para realizar tu depósito a servicio  al cliente de <strong>TujugadaRD</strong> a través de <strong>nuestro chat integrado</strong>.",
      "Realiza la transferencia del monto deseado y toma una captura de pantalla del comprobante de la transacción.",
      "Carga la captura de pantalla a través del boton que se presenta más abajo para que el saldo sea acreditado en tu cuenta o lo puede envia a nuestros numeros de whatsapp: +1 (809) 916-9537 / +1 (809) 712-1522.",
    ],
    tetherTips: {
      description:
        "Compra cripto al instante y transfiérelas automáticamente a tu saldo de <strong>TujugadaRD</strong>. ¡Simplemente selecciona a continuación la cantidad y la billetera en la que deseas depositarlo!",
      linkLabel: "Haz clic aqui.",
    },
    creditCardClientTips: {
      btnPayment: "Procesar pago",
      error: "Ocurrio un error al obtener el cliente de pago.",
      success: "Depósito procesado exitosamente.",
      cancelled: "Depósito cancelado exitosamente.",
      successTitle: "Depósito procesado",
      cancelledTitle: "Depósito cancelado",
      requiredAmount: "Debe ingresar un monto.",
      minAmount: "El monto mínimo es de {min}.",
      okText: "Aceptar",
      process: {
        title: "Procesar pago",
        redirecting: "Redireccionando a la pasarela de pago...",
        description:
          '¡Nuestros pagos con tarjeta de crédito y débito son seguros, instantáneos y discretos!. Serás redirigido a nuestra pasarela de pago segura después de hacer clic en "Realizar pedido".',
        btnProcess: "Realizar pedido",
      },
      amount: "Monto",
      fee: "Comisión",
      total: "Total",
    },
    save: {
      label: "¿Adicionar a mis cuentas?",
    },
    selectAccountError: "Debe seleccionar o agregar una cuenta.",
    bankAccountInfoLabel: "Información de la cuenta",
    bankAndAccountTypeLabel: "Banco y tipo de cuenta:",
    bankAccountNumberLabel: "Número de cuenta:",
    identificationCardLabel: "Cédula:",
    confirmButtonText: "Confirmar",
    saveAccountButtonText: "Guardar cuenta",
    deleteAccountTitle: "Eliminar cuenta",
    deleteAccountContent: "¿Estás seguro que deseas eliminar esta cuenta?",
    deleteAccountOkText: "Sí, eliminar",
    deleteAccountCancelText: "Cancelar",
    whatsappMessage: "Quiero hacer un depósito.",
    submit: "Enviar solicitud",
    success: "Solicitud enviada exitosamente.",
    transferSuccess: "Transferencia realizada exitosamente.",
    error: "Ocurrio un error al enviar la solicitud.",
    copiedToClipboard: "Copiado al portapapeles",
    tetherDirTitle: "Tu Dirección Tether",
    copyToClipboard: "Copiar Dirección Tether (USDT)",
    loadingQRCode: "Cargando código QR...",
    btnAmounts: [300, 500, 1000, 2500],
    confirmPolicy: {
      title: "¡Información importante!",
      content:
        "En el comprobante de deposito o transferencia debe colocar SOLAMENTE su nombre y apellido. Esto con fines de acelerar el proceso de confirmación y agilizar su recarga.",
      okText: "De acuerdo",
      cancelText: "Cancelar",
    },
    transfer: {
      title: "Detalles",
      amount: "Monto",
      charges: "Cargo",
      total: "Total",
    },
    confirmPassword: {
      title: "Confirmar datos para enviar solicitud",
      subtitle:
        "Favor escriba su contraseña de usuario para confirmar la transferencia de balance",
      confirmButtonText: "Enviar",
      cancelButtonText: "Cancelar",
    },
  },
  whatsappWidget: {
    title: "WhatsApp",
    subtitle: "Click para escribirnos",
    error: "No se ha configurado el número de WhatsApp",
  },
  buy: {
    title: "Comprar ticket",
    subtitle: "Comprar ticket",
    ticketList: "Lista de jugadas",
    lotteryCLoseTimeLabel: (value) => `Sorteo cierra a las ${value}.`,
    payError: "Ocurrio un error al pagar el ticket.",
    mixPaleError: "Ocurrión un error al intentar crear los Pale",
    mixTripletaError: "Ocurrión un error al intentar crear las Tripletas",
    mixPaleTripletaError:
      "Ocurrión un error al intentar crear los Pale y las Tripletas",
    sharedError: "Ocurrio un error al leer el ticket compartido.",
    insufficientBalance:
      "Usted no tiene suficiente saldo para pagar el ticket.",
    nonRepeated2Pools: "Debes tener almenos dos Quinielas no repetidas",
    nonRepeated3Pools: "Debes tener almenos tres Quinielas no repetidas",
    form: {
      title: "Comprar ticket",
      addPlays: "Agregar jugadas",
      mixTitle: "Mezcador de jugadas",
      addMixes: "Agregar combinaciones",
      cleanMixes: "Limpiar combinaciones",
      mixesTitle: "Combinaciones",
      mixesQuinielas: "Quinielas",
      mixesPales: "Pales",
      mixesSuperpales: "Superpales",
      mixesTripletas: "Tripletas",
      loading: "Cargando...",
      clear: "Eliminar jugadas",
      clearError: "Aun no ha agregado jugadas al carrito.",
      deleteMix: "Eliminar combinación",
      pay: "Pagar ticket",
      noPlay: "Sin jugada",
      playTypes: {
        quiniela: "Quiniela",
        pale: "Pale",
        superpale: "Super pale",
        tripleta: "Tripleta",
        auto: "Auto",
        pick3: "Pick 3",
        pick4: "Pick 4",
      },
      date: {
        label: "Fecha",
        placeholder: "--/--/----",
      },
      isSuperpale: {
        label: "Es super pale?",
      },
      mix: {
        label: "Mezclador de jugadas?",
      },
      lotteries: {
        label: "Loterias",
        placeholder: "Seleccione una o más loterias.",
        required: "Debe seleccionar al menos una loteria.",
        min: (min) => `Debe seleccionar al menos ${min} loteria(s).`,
        timeLeft: "Tiempo restante para cierre de lotería",
        outOfTime:
          "Algunas de las loterías en el enlace está fuera de horario.",
      },
      superPale: {
        label: "Super pale",
        placeholder: "00-00",
      },
      numbers: {
        label: "Números",
        placeholder: "00-00-00",
        placeholderQuiniela: "00",
        placeholderPale: "00-00",
      },
      number: {
        label: "Números a Mezclar",
        placeholder: "00",
      },
      amount: {
        label: "Monto",
        placeholder: "0",
      },
      quinielaAmount: {
        label: "Quiniela",
        placeholder: "$ 0",
      },
      paleAmount: {
        label: "Pale",
        placeholder: "$ 0",
      },
      superpaleAmount: {
        label: "Superpale",
        placeholder: "$ 0",
      },
      tripletaAmount: {
        label: "Tripleta",
        placeholder: "$ 0",
      },
      add: "Agregar",
    },
    table: {
      total: "Total",
      play: "Loteria / Jugada",
      type: "Tipo de jugada",
      lottery: "Loteria",
      numbers: "Números",
      amount: "Monto",
      action: {
        title: "Acción",
        remove: "Eliminar Jugada",
      },
    },
    payment: {
      title: "Revisa tu ticket",
      payTicket: "Pagar Ticket",
      totalTicket: "Total:",
      success: "Ticket pagado & generado exitosamente.",
      ticketLabel: "Ticket #:",
      pay: "Pagar",
      noAvailablePlays: "No hay jugadas disponibles.",
    },
    success: {
      cancelText: "Aceptar",
      okText: "Ver/Imprimir Ticket",
    },
    rebuyTicketModal: {
      rebuyTitle: "Juégalo otra vez",
      sharedTitle: "Ticket compartido",
      next: "Siguiente",
      prev: "Anterior",
      cancel: "Cancelar",
      addPlays: "Agregar",
      addPlay: "Agregar jugada",
      form: {
        confirmPlaysAndAdd: "Confirmar jugadas y agregar",
        superpaleError:
          "Debe seleccionar dos loterias o más para el super pale.",
        lotteries: {
          label: "Selecciona la(s) loteria(s)",
          placeholder: "Selecciona una o más loterias.",
          required: "Debe seleccionar al menos una loteria.",
          min: (min) => `Debe seleccionar al menos ${min} loteria(s).`,
        },
        plays: {
          label: "Lista de jugadas",
        },
      },
    },
  },
  commissionProfiles: {
    title: "Perfiles de comisiones",
    subtitle: "Listado de perfiles de comisiones",
    placeholderSearch: "Buscar por nombre.",
    add: "Añadir",
    deletedSuccess: "Perfil de comisión eliminado exitosamente.",
    deletedError: "Ocurrio un error al eliminar el perfil de comisión.",
    pageTitleViewing: "Viendo perfil de comisión",
    pageTitleCreate: "Crear perfil de comisión",
    confirm: {
      title: "¿Estás seguro que deseas eliminar este perfil de comisión?",
      subtitle: "Esta acción no se puede deshacer.",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
    },
    filters: {
      title: "Aplicar filtros",
      filters: "Filtros",
      form: {
        cancel: "Cancelar",
        save: "Aplicar",
        dateRange: {
          label: "Rango de fechas",
          placeholder: {
            startdate: "Fecha inicial",
            enddate: "Fecha final",
          },
        },
        type: {
          label: "Tipo",
          placeholder: "Selecciona el tipo.",
        },
      },
    },
    table: {
      emptyText: "No se encontraron perfiles de comisiones.",
      columns: {
        id: "ID",
        name: "Nombre",
        profile: "Sub Perfil",
        status: {
          title: "Estado",
          active: "Activo",
          inactive: "Inactivo",
        },
        createdAt: "Fecha de creación",
        schemas: "Esquemas",
        benefits: "Beneficios",
        action: {
          title: "Acciones",
          edit: "Editar",
          delete: "Eliminar",
        },
      },
    },
    form: {
      title: "Información de",
      titleView: "Perfil de comisión",
      titleCreate: "Crear perfil de comisión",
      save: "Guardar",
      saveAndClose: "Guardar & cerrar",
      goBack: "Atrás",
      loading: "Cargando...",
      deleteCommissionProfile: "Eliminar",
      name: {
        label: "Nombre",
        placeholder: "Introduce el nombre.",
      },
      profile: {
        label: "Sub perfil",
        placeholder: "Seleccione un sub perfil.",
      },
      status: {
        label: "Estado",
        placeholder: "Selecciona el estado.",
        active: "Activo",
        inactive: "Inactivo",
      },
      type: {
        label: "Tipos",
        referralCommission: {
          label: "Bono por referido",
          value: "REFERRAL_COMMISSION",
          ruleMinimumAmount: {
            label: "Monto mínimo para aplicar bono",
            placeholder: "0.00",
          },
          amount: {
            label: "Monto bono",
            placeholder: "0.00",
          },
          status: {
            label: "Estado",
            placeholder: "Estado del bono",
            active: "Habilitado",
            inactive: "Deshabilitado",
          },
        },
        affiliateNetworkCommission: {
          label: "Comisión red afiliados",
          value: "AFFILIATE_NETWORK_COMMISSION",
          level1Percentage: {
            label: "Nivel 1",
            placeholder: "% 0",
          },
          level2Percentage: {
            label: "Nivel 2",
            placeholder: "% 0",
          },
          status: {
            label: "Estado",
            placeholder: "Estado de la comisión",
            active: "Habilitado",
            inactive: "Deshabilitado",
          },
        },
        userDepositCommission: {
          label: "Comisión por depósito",
          value: "USER_DEPOSIT_COMMISSION",
          ruleMinimumAmount: {
            label: "Monto mínimo para aplicar comisión",
            placeholder: "0.00",
          },
          percentage: {
            label: "Porcentaje",
            placeholder: "% 0",
          },
          status: {
            label: "Estado",
            placeholder: "Estado de la comisión",
            active: "Habilitado",
            inactive: "Deshabilitado",
          },
        },
        userRegistrationBonus: {
          label: "Bono por registro (Referido)",
          value: "USER_REGISTRATION_BONUS",
          amount: {
            label: "Monto bono",
            placeholder: "0.00",
          },
          status: {
            label: "Estado",
            placeholder: "Estado del bono",
            active: "Habilitado",
            inactive: "Deshabilitado",
          },
        },
        userRegistrationBonusClient: {
          label: "Bono por registro (Cliente)",
          value: "USER_REGISTRATION_BONUS_CLIENT",
          amount: {
            label: "Monto bono",
            placeholder: "0.00",
          },
          status: {
            label: "Estado",
            placeholder: "Estado del bono",
            active: "Habilitado",
            inactive: "Deshabilitado",
          },
        },
      },
    },
  },
  transactionProfiles: {
    title: "Perfiles de transacciones",
    subtitle: "Listado de perfiles de transacciones",
    placeholderSearch: "Buscar por nombre.",
    add: "Añadir",
    deletedSuccess: "Perfil de transacción eliminado exitosamente.",
    deletedError: "Ocurrio un error al eliminar el perfil de transacción.",
    pageTitleViewing: "Viendo perfil de transacción",
    pageTitleCreate: "Crear perfil de transacción",
    confirm: {
      title: "¿Estás seguro que deseas eliminar este perfil de transacción?",
      subtitle: "Esta acción no se puede deshacer.",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
    },
    filters: {
      title: "Aplicar filtros",
      filters: "Filtros",
      form: {
        cancel: "Cancelar",
        save: "Aplicar",
        dateRange: {
          label: "Rango de fechas",
          placeholder: {
            startdate: "Fecha inicial",
            enddate: "Fecha final",
          },
        },
        type: {
          label: "Tipo",
          placeholder: "Selecciona el tipo.",
        },
      },
    },
    table: {
      emptyText: "No se encontraron perfiles de transacciones.",
      columns: {
        id: "ID",
        name: "Nombre",
        status: {
          title: "Estado",
          active: "Activo",
          inactive: "Inactivo",
        },
        createdAt: "Fecha de creación",
        schemas: "Reglas",
        action: {
          title: "Acciones",
          edit: "Editar",
          delete: "Eliminar",
        },
      },
    },
    form: {
      title: "Información de",
      titleView: "Perfil de transacción",
      titleCreate: "Crear perfil de transacción",
      save: "Guardar",
      saveAndClose: "Guardar & cerrar",
      goBack: "Atrás",
      loading: "Cargando...",
      deleteTransactionProfile: "Eliminar",
      name: {
        label: "Nombre",
        placeholder: "Introduce el nombre.",
      },
      status: {
        label: "Estado",
        placeholder: "Selecciona el estado.",
        active: "Activo",
        inactive: "Inactivo",
      },
      confirm: {
        title: "Seleccione un tipo de esqueleto para continuar.",
        cancelText: "Cancelar",
        okText: "Aceptar",
        label: "Tipo",
        placeholder: "Selecciona el tipo.",
        error: "Debe seleccionar un tipo.",
      },
      type: {
        label: "Tipos",
        description: "Agregar reglas para el perfil de transacción.",
        transactionQuantityLimit: {
          label: "Límite de cantidad por intervalo de tiempo",
          value: "transaction_quantity_limit",
          interval: {
            label: "Intervalo",
            placeholder: "Selecciona el intervalo.",
            minutes: "Minuto",
            hours: "Hora",
            days: "Día",
            weeks: "Semana",
            months: "Mes",
          },
          quantity: {
            label: "Cantidad",
            placeholder: "0",
          },
          status: {
            label: "Estado",
            placeholder: "Estado del bono",
            active: "Habilitado",
            inactive: "Deshabilitado",
          },
        },
        transactionAmountLimit: {
          label: "Límite de monto por intervalo de tiempo",
          value: "transaction_amount_limit",
          interval: {
            label: "Intervalo",
            placeholder: "Selecciona el intervalo.",
            minutes: "Minuto",
            hours: "Hora",
            days: "Día",
            weeks: "Semana",
            months: "Mes",
          },
          amount: {
            label: "Monto",
            placeholder: "0.00",
          },
          status: {
            label: "Estado",
            placeholder: "Estado del bono",
            active: "Habilitado",
            inactive: "Deshabilitado",
          },
        },
      },
    },
  },
  userbalancemovementList: {
    title: "Movimientos de saldo",
    subtitle: "Listado de movimientos de saldo",
    placeholderSearch: "Buscar por número, tipo, referencia o descripción.",
    table: {
      emptyText: "No se encontraron movimientos de saldo.",
      columns: {
        date: "Fecha",
        number: "Número",
        user: "Cliente",
        reference: "Referencia",
        debit: "Débito",
        credit: "Crédito",
        balance: "Balance",
        description: "Descripción",
        type: {
          title: "Tipo",
          DEBIT: "Débito",
          CREDIT: "Crédito",
        },
        movements: "Movimientos",
      },
    },
    filters: {
      title: "Aplicar filtros",
      filters: "Filtros",
      form: {
        cancel: "Cancelar",
        save: "Aplicar",
        dateRange: {
          label: "Rango de fechas",
          placeholder: {
            startdate: "Fecha inicial",
            enddate: "Fecha final",
          },
        },
        type: {
          label: "Tipo",
          placeholder: "Selecciona el tipo.",
          debit: "Débito",
          credit: "Crédito",
        },
        user: {
          label: "Cliente",
          placeholder: "Selecciona el cliente.",
        },
        reference: {
          label: "Referencia",
          placeholder: "Introduce la referencia.",
        },
        number: {
          label: "Número",
          placeholder: "Introduce el número.",
        },
      },
    },
    confirmCancellation: {
      title: "Cancelar movimiento",
      content: "Favor de confirmar que desea cancelar el movimiento #{code}.",
      okText: "Sí, cancelar",
      cancelText: "Cerrar",
    },
    cancelTransferSuccess: "Movimiento cancelado exitosamente.",
    cancelTransferError: "Ocurrio un error al cancelar el movimiento.",
    moreInfo: {
      title: "Información de movimiento {code}",
      date: "Fecha",
      code: "Número",
      reference: "Ticket/Referencia",
      type: "Tipo",
      origin: "Origen",
      amount: "Monto",
      status: "Estado",
    },
  },
  monitoringReport: {
    title: "Reporte de monitoreo",
    subtitle: "Reporte de monitoreo de jugadas",
    table: {
      emptyText: "No se encontraron jugadas.",
      columns: {
        lottery: "Loteria",
        date: "Fecha",
        type: "Tipo",
        numbers: "Números",
        percentage: "Porcentaje",
        amount: "Monto",
        amountToWin: "A ganar",
        closeTime: "Cierre",
        plays: "Jugadas",
      },
    },
  },
  lotteriesSchedules: {
    title: "Programación cierre de loterias",
    pageTitleCreate: "Crear programación",
    pageTitleEditing: "Editando programación",
    subtitle: "Lista de programación de cierre de loterias",
    add: "Añadir",
    edit: "Editar",
    delete: "Eliminar",
    placeholderSearch: "Buscar título, fecha y loterías.",
    deletedSuccess: "Programación eliminada exitosamente.",
    deleteError: "Ocurrio un error al eliminar la programación.",
    table: {
      emptyText: "No se encontraron programaciones.",
      columns: {
        lotteriesSchedule: "Programación de cierre de loterias",
        date: "Fecha",
        lotteries: "Loterias",
        title: "Título",
        annually: {
          title: "Anual",
          yes: "Si",
          no: "No",
        },
        action: {
          title: "Acciones",
          edit: "Editar",
          delete: "Eliminar",
        },
      },
    },
    confirm: {
      title: "¿Estás seguro que deseas eliminar esta programación?",
      subtitle: "Esta acción no se puede deshacer.",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    },
    form: {
      titleEdit: "Editar programación",
      titleAdd: "Añadir programación",
      save: "Guardar",
      saveAndClose: "Guardar & cerrar",
      goBack: "Atrás",
      loading: "Cargando...",
      date: {
        label: "Fecha",
        placeholder: "Selecciona una fecha.",
      },
      title: {
        label: "Título",
        placeholder: "Introduce el título.",
      },
      lotteries: {
        label: "Loterias",
        placeholder: "Selecciona una o más loterias.",
        required: "Debe seleccionar al menos una loteria.",
        min: (min) => `Debe seleccionar al menos ${min} loteria(s).`,
      },
      annually: {
        label: "Anual",
        placeholder: "¿Repetir anualmente?",
        yes: "Si",
        no: "No",
      },
    },
  },

  userNotificationsList: {
    title: "Notificaciones",
    subtitle: "Listado de notificaciones del sistema",
    placeholderSearch: "Buscar por número, tipo o fecha.",
    table: {
      emptyText: "No se encontraron notificaciones.",
      columns: {
        date: "Fecha",
        subject: "Asunto",
        message: "Mensaje",
        read: "Estado",
        // new: "Nuevo",
        // topic: "Tipo",
        // sender: "Usuario",
        action: "Acciones",
      },
    },
    filters: {
      title: "Aplicar filtros",
      filters: "Filtros",
      form: {
        dateRange: {
          label: "Rango de fechas",
          placeholder: {
            startdate: "Fecha inicial",
            enddate: "Fecha final",
          },
        },
      },
    },
    // confirmRead: {
    //   title: "Marcar como Leída",
    //   content: "Favor de confirmar que desea cancelar el estado.",
    //   okText: "Sí",
    //   cancelText: "Cerrar",
    // },
    read: {
      true: "Leída",
      false: "No leída",
    },
    actions: {
      moreInfo: "Ver detalle",
      delete: {
        title: "¿Estás seguro de eliminar esta notificación?",
        content: "Esta acción no se puede deshacer.",
        okText: "Sí, eliminar",
        cancelText: "Cerrar",
      },
    },
    moreInfo: {
      mainTitle: "Información de la notificación {code}",
      subject: "Asunto",
      date: "Fecha",
      topic: "Tipo",
      sender: "Enviado por",
      message: "Mensaje",
      body: "Detalle",
    },
  },
  welcomePage: {
    title: "Página de bienvenida",
    subtitle: "Configuración de la página de bienvenida",
    description: "Página de bienvenida",
    loading: "Cargando...",
    playNow: "Juega ahora",
  },

  verifyPhoneForm: {
    title: "Verifica tu número de teléfono",
    subtitle: "Estas solo a aun paso de finalizar, verifica tu número",
    subtitle_bonus:
      "Confirma tu número de teléfono y gana un bono de bievenida de {variable}",
    before: "Un código de verificación será enviado al número {phone}",
    after: "Acabamos de enviar un código al {phone}",
    form: {
      code: {
        label: "introducir código de verificación",
        placeholder: "",
      },
      sent_success: "Código enviado.",
      success: "Validación correcta.",
      error: "Validación incorrecta.",
      error_code: "El código no es válido, intenta nuevamente.",
      submit: "Verificar",
      send: "Enviar",
    },
  },
  confirmPromotion: {
    title: "Aplicar promoción",
    subtitle: "Escribe el código de la promoción",
    appliedSuccess: "Promoción aplicada exitosamente.",
    appliedError: "Ocurrio un error al aplicar la promoción.",
    code: {
      label: "Código de promoción",
      placeholder: "Introduce el código de promoción",
      required: "El código de promoción es requerido.",
    },
    confirmButtonText: "Aplicar",
    cancelButtonText: "Cancelar",
  },
};

export default labels;
